import { Component, h, Host, Prop } from '@stencil/core';
import { PTZSpinnerConfig } from './types/ptz-spinner.enums';
import { PTZSpinnerTypes } from './types/ptz-spinner.types';


@Component({
  tag: 'ptz-spinner',
  styleUrl: 'ptz-spinner.scss',
  shadow: false
})

export class PTZSpinner {
  /** Cor onde o Spinner é aplicado */
  @Prop() appearance?: PTZSpinnerTypes.Appearance = PTZSpinnerConfig.Appearance.Light

  /** Tamanho do Spinner */
  @Prop() size?: PTZSpinnerTypes.Size = PTZSpinnerConfig.Size.Md

  render() {
    return (
      <Host>
        <svg data-testid={`${this.appearance}-spinner-${this.size}`} class={`ptz-spinner ${this.size ? `ptz-spinner-${this.size}` : ''}`} viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="ptz-spinner-background" cx="33" cy="33" r="30"></circle>
          <circle class={`ptz-spinner-circle ${this.appearance ? `ptz-spinner-circle-${this.appearance}` : ''}`} cx="33" cy="33" r="30"></circle>
        </svg>
      </Host>
    );
  }
}
