import { EnumToArray } from '../../../utils/enumToArray';

enum Size {
  Xs = 'xs',
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
}

enum Appearance {
  Light = 'light',
  Dark = 'dark',
  Color = 'color',
}

export const PTZSpinnerConfigList = {
  Appearance: EnumToArray([Appearance]),
  Size: EnumToArray([Size]),
};

export const PTZSpinnerConfig = {
  Appearance,
  Size,
};
