ptz-spinner {
  display: flex;

  .ptz-spinner {
    animation: spin 1.4s linear infinite;

    &-lg {
      width: $petz-spacing-lg;
      height: $petz-spacing-lg;
    }

    &-md {
      width: $petz-spacing-md;
      height: $petz-spacing-md;
    }

    &-sm {
      width: calc($petz-spacing-md - .25rem);
      height: calc($petz-spacing-md - .25rem);
    }

    &-xs {
      width: $petz-spacing-sm;
      height: $petz-spacing-sm;
    }

    &-circle {
      fill: none;
      stroke-width: 6px;
      stroke-linecap: round;
      stroke-dasharray: 187;
      stroke-dashoffset: 0;
      transform-origin: center;
      animation: dash 1.4s ease-in-out infinite;

      &-light {
        stroke: $petz-color-neutral-black;
      }

      &-dark,
      &-color {
        stroke: $petz-color-neutral-white;
      }
    }

    &-background {
      fill: none;
      stroke-width: 6px;
      stroke-linecap: round;
      stroke: $petz-color-neutral-mid;
      opacity: $petz-opacity-md;
    }
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }

  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(-90deg);
  }

  10% {
    transform: rotate(-90deg);
  }

  100% {
    transform: rotate(180deg);
  }
}
